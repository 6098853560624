import { environment } from '../../environments/environment';
import { AssetStatus } from '../modules/assets/dtos/asset-status';
import { EntityType } from '../modules/entities/dtos/entities';
import { EditBillingDialogData } from '../modules/billing/pages/editBillingDetails/edit-billing-details.component';

const { baseApiUrl } = environment;

export const listProjectsUrl = () => {
  return `${baseApiUrl}project`;
};

export const projectUrl = (projectId: number) => {
  return `${listProjectsUrl()}/${projectId}`;
};

export const projectConversionsUrl = (projectId: number) => {
  return `${listProjectsUrl()}/${projectId}/conversions`;
};

const baseAccountUrl = `${baseApiUrl}account`;

export const listAccountsUrl = () => {
  return `${baseAccountUrl}/search`;
};

export const accountUrl = (accountId: number) => {
  return `${baseAccountUrl}/${accountId}`;
};

export const createAccountUrl = () => {
  return `${baseAccountUrl}/create`;
};
export const updateAccountUrl = () => {
  return `${baseAccountUrl}/update`;
};
export const deleteAccountUrl = (accountId: number) => {
  return `${baseAccountUrl}/delete/${accountId}`;
};

export const createAccountEntryUrl = () => {
  return `${baseAccountUrl}/account-entry/add`;
};

export const updateTransactionTypeUrl = () => {
  return `${baseAccountUrl}/transaction/update-type`;
};

export const listOwnerAccounts = (ownerId: string) => {
  return `${baseAccountUrl}/owner/${ownerId}`;
};

export const listTransactionsUrl = () => {
  return `${baseAccountUrl}/transactions/list`;
};

export const transactionItemsUrl = (transactionId: number) => {
  return `${baseAccountUrl}/transactions/${transactionId}/items`;
};

export const accountEntryTotalsByOwnerUrl = (ownerId: string) => {
  return `${baseAccountUrl}/owner/${ownerId}/totals`;
};

export const accountEntryBalancesByOwnerUrl = (ownerId: string) => {
  return `${baseAccountUrl}/owner/${ownerId}/account-balances`;
};

export const accountUsersTransactionIdExists = (userTransactionId: string) => {
  return `${baseAccountUrl}/transactions/${userTransactionId}/exists`;
};

export const finalizePendingTransactionUrl = (transactionId: number) => {
  return `${baseAccountUrl}/transactions/${transactionId}/finalize`;
};

export const accountReconciliationFilesUrl = (accountId: number) => {
  return `${baseAccountUrl}/${accountId}/reconciliation-files`;
};

export const accountReconciliationFileEntriesUrl = (fileId: number) => {
  return `${baseAccountUrl}/reconciliation-files/${fileId}/entries`;
};

export const accountReconciliationEntryCreditAccountUrl = (
  reconciliationAccountId: number,
  entryId: number
) => {
  return `${baseAccountUrl}/${reconciliationAccountId}/reconciliation-entry/${entryId}/credit-account`;
};

export const accountReconciliationEntryPendingTransactionEntriesUrl = (
  reconciliationAccountId: number,
  entryId: number
) => {
  return `${baseAccountUrl}/${reconciliationAccountId}/reconciliation-entry/${entryId}/pending-transaction-entries`;
};

export const accountReconcileEntryUrl = (
  reconciliationAccountId: number,
  entryId: number
) => {
  return `${baseAccountUrl}/${reconciliationAccountId}/reconciliation-entry/${entryId}/reconcile`;
};

export const withdrawFundsUrl = () => {
  return `${baseAccountUrl}/withdraw-funds`;
};

export const assetPrice = (assetUniqueId: string | undefined) => {
  return `${baseAccountUrl}/total-debit/${assetUniqueId}`;
};

const baseAssetUrl = `${baseApiUrl}asset`;

export const listAssetsUrl = () => {
  return `${baseAssetUrl}/search`;
};

export const assetUrl = (assetId: number) => {
  return `${baseAssetUrl}/${assetId}`;
};

export const assetDefinitionsUrl = () => {
  return `${baseAssetUrl}/definitions`;
};

export const saveAssetUrl = () => {
  return `${baseAssetUrl}/save`;
};

export const deleteAssetUrl = (assetId: number) => {
  return `${baseAssetUrl}/${assetId}`;
};

export const toggelAssetStatusUrl = (
  assetId: number,
  newStatus: AssetStatus
) => {
  return `${baseAssetUrl}/${assetId}/updatestatus/${newStatus}`;
};

export const getAssetCertificateUrl = (assetId: number) => {
  return `${baseAssetUrl}/${assetId}/certificate`;
};

export const getAssetSasTokenUrl = (assetId: string) => {
  return `${baseAssetUrl}/${assetId}/sas-token`;
};

export const assetsSendMessage = () => {
  return `${baseAssetUrl}/send-message`;
};

export const adjustPrice = () => {
  return `${baseAssetUrl}/adjust-price`;
};

const baseCertificateUrl = `${baseApiUrl}certificate`;
export const caCertificateListUrl = () => `${baseCertificateUrl}/ca`;
export const caCertificateUploadUrl = () => `${baseCertificateUrl}/ca`;

const baseEntityUrl = `${baseApiUrl}entity`;
export const entityListUrl = () => baseEntityUrl;
export const entityWithProjects = (entityId: number) =>
  `${baseEntityUrl}/${entityId}/projects`;

export const validateEntityEmail = (email: string) =>
  `${baseEntityUrl}/validate-email/${email}`;

const baseBillingUrl = `${baseApiUrl}billing`;
export const billingDetailsUrl = (entityType?: EntityType, entityId?: number, projectId?: number) =>
  `${baseBillingUrl}/details?entityType=${entityType}&entityId=${entityId}&projectId=${projectId}`;

export const getBillingDetailsUrl = () =>
  `${baseBillingUrl}/billing-groups`;
export const getPagingBillingRulesUrl = (projectId?: number) =>
  `${baseBillingUrl}/project-billing-rules/${projectId}`;

export const getPagingBillingGroupUrl = (projectId: number) =>
  `${baseBillingUrl}/project-billing-groups/${projectId}`;

export const createBillingGroupUrl = (projectId: number) =>
  `${baseBillingUrl}/project-billing-group/${projectId}`;

export const createBillingGroupAssignmentUrl = (groupId: number, entityId: number) =>
  `${baseBillingUrl}/group-assignment?groupId=${groupId}&entityId=${entityId}`;
export const createBillingRuleUrl = (projectId?: number) =>
  `${baseBillingUrl}/project-billing-rule/${projectId}`;
export const setDefaultGroupUrl = (projectId: number | undefined, groupId: number | undefined, type: EntityType | undefined) =>
  `${baseBillingUrl}/project-billing-default/${projectId}/${groupId}/${type}`;
export const createBillingListRuleUrl = (projectId: number | undefined, groupId: number | undefined) => {
  let url = `${baseBillingUrl}/project-billing-rule-list/${projectId}`;
  if(groupId){
    url = `${url}/${groupId}`;
  }
  return url;
};

const baseFileUploadUrl = `${baseApiUrl}PublicFile`;

export const uploadSingleFile = (directory: string) =>
  `${baseFileUploadUrl}/file/${directory}`;

export const uploadSingleFileThumbnail = (directory: string) =>
  `${baseFileUploadUrl}/thumbnail/${directory}`;

const baseAuthUrl = `${baseApiUrl}auth`;
export const getAuthUserUrl = () => {
  const baseUrl = baseAuthUrl.replace('/v1.0', '');
  return `${baseUrl}/getuser`;
};

export const adminUiSettingsUrl = () => {
  return `${baseApiUrl}referenceData/admin-ui-settings`;
};
